//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['widget'],
    computed: {
        widgets() {
            if (this.widget && this.widget.fields) {
                return this.widget.fields.widgets;
            }
            return [];
        },
        col() {
            const dontPurge = ['lg:col-span-12', 'lg:col-span-6', 'lg:col-span-4', 'lg:col-span-3', 'lg:col-span-2'];
            const calculation = 12 / this.widgets.length;
            return `lg:col-span-${calculation}`;
        },
    },
};

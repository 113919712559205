//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cmsUrls from '~/mixins/cmsUrls';

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: false,
            default: '',
        },
        linkUrl: {
            type: String,
            required: false,
            default: '',
        },
        linkTitle: {
            type: String,
            required: false,
            default: '',
        },
        imageUrl: {
            type: String,
            required: false,
            default: '',
        },
        openInNewTab: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mixins: [cmsUrls],
};
